// Importing these modules via script tags caused console errors
// Here is an ugly workaround to add them to the global scope
import * as mdb from 'mdb-ui-kit';
import {Chart} from 'mdb-ui-kit/js/chart.es.min.js';
import * as Treeview from 'mdb-ui-kit/plugins/js/treeview.min.js';

window.mdb = mdb;
window.mdb.Chart = Chart;
window.Treeview = Treeview;

// Workaround to import images outside ES modules
// Reference: https://parceljs.org/recipes/image/#javascript
const favicon = new URL('../img/favicon.svg', import.meta.url);
const logo = new URL('../img/logo.svg', import.meta.url);
const wysiwyg = new URL('../css/wysiwyg.css', import.meta.url);

window.fileUrls = {
  favicon,
  logo,
  wysiwyg,
};
